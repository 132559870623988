// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const validarDominio = () => {
  if (window.location.host === 'localhost:4207' || window.location.host === 'localhost:4200') {
    console.log('localhost');
    // return 'romomillan';
    return 'grupomc';
  }
  console.log('window.location.host', window.location.host);
  return window.location.host;
};


export const environment = {
  production: true,
  hmr: false,

  // ULTIMO COMENTADO (funcional)
  //Produccion
  API_URL: "https://back.inmoapp.mx/api/admin/",
  API_URL_LANDING: "https://back.inmoapp.mx/api/landing/",
  URL: "https://back.inmoapp.mx",
  URL_LANDING: "https://inmoapp.mx/",
  URL_SUBDOMAIN_LANDING: ".inmoapp.mx",
  URL_SUBDOMAIN_LANDING2: ".inmoapp.mx",

  // TESTING
  // API_URL: "https://qaback.in-mo.app/api/admin/",
  // API_URL_LANDING: "https://qaback.in-mo.app/api/landing/",
  // URL: "https://qaback.in-mo.app",
  // URL_LANDING: "https://qa.in-mo.app/ ",

  //LOCAL
  // API_URL: "http://localhost:8000/api/admin/",
  // API_URL_LANDING: "http://localhost:8000/api/landing/",
  // URL: "http://localhost:8000",
  // URL_LANDING: "https://in-mo.app/",
  // URL_SUBDOMAIN_LANDING: '.in-mo.app',

  // DOMAIN: validarDominio(),
  DOMAIN: window.location.host,
  // DOMAIN: "romomillan",

  URL_AWS: "https://inmoapp-storage.s3.us-west-1.amazonaws.com/",
  MAPS_API_KEY: "AIzaSyCX2xPTQp5j8T3pla2GSzRuYTi8z_QVEME",
  PUBLIC_KEY_MP: "TEST-e14b6667-bac3-427a-a24d-49fc7cfcb714",
  ACCESS_TOKEN_MP: "TEST-8824949066921584-111814-c2d50104e475719538bc0c8efa9bdb31-453316830",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

